import styled, { css } from 'styled-components'
import { Modal } from 'antd'

export const StartButton = styled.div<{
  isFinish: boolean
  isSpinning: boolean
}>`
  background-image: url('img/contro.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 5.208vw;
  height: 8.333vw;
  max-width: 58px;
  max-height: 88px;
  z-index: 100;
  cursor: pointer;

  ${({ isFinish }) =>
    isFinish &&
    css`
      background-image: url('img/controhetluot.png');
    `}

  ${({ isSpinning }) =>
    isSpinning &&
    css`
      background-image: url('img/controtat.png');
    `}

  @media (max-width: 768px) {
    width: 50px;
    height: 100px;
  }
`

export const StyledModal = styled(Modal)`
  && {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-content {
      border-radius: 8px;
    }
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;
  min-height: 388px;
`

export const SpinWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export const Spin = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: 992px) {
    min-height: 870px;
  }
`

export const SpinInnerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  canvas {
    border-radius: 100%;
    width: 33.5vw;
    height: 33.5vw;
    max-width: 508px;
    max-height: 508px;
    z-index: 100;
  }

  @media (max-width: 1024px) {
    margin-top: 28px;

    canvas {
      width: 42vw;
      height: 42vw;
    }
  }

  @media (max-width: 560px) {
    margin-top: 24px;
    canvas {
      width: 68vw;
      height: 68vw;
    }
  }
`

export const GradientBg = styled.div`
  background: url('../img/wheel-background.png') no-repeat center center;
  background-position: center center;
  background-size: cover;
  width: 36vw;
  height: 36vw;
  max-width: 548px;
  max-height: 548px;
  position: absolute;
  border-radius: 100%;
  z-index: 99;

  @media (max-width: 1024px) {
    width: 46vw;
    height: 46vw;
  }

  @media (max-width: 560px) {
    width: 74vw;
    height: 74vw;
  }
`

export const SpinBottom = styled.img`
  width: 12.5vw;
  position: absolute;
  bottom: -48px;
  left: calc(50% - 12.5vw / 2);
  z-index: 98;

  @media (max-width: 1400px) {
    bottom: -34px;
  }

  @media (max-width: 768px) {
    width: 140px;
    bottom: -30px;
    left: calc(50% - 70px);
  }

  @media (max-width: 560px) {
    width: 100px;
    bottom: -30px;
    left: calc(50% - 50px);
  }
`

export const SlotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 150px;
  min-height: 150px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
  }

  span {
    z-index: 999;
    text-shadow: 2px 2px 5px #f7ff29;
    color: white;
    font-size: 48px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    width: 120px;
    min-height: 120px;

    img {
      width: 120px;
    }

    span {
      font-size: 36px;
    }
  }

  @media (max-width: 560px) {
    width: 72px;
    min-height: 72px;

    img {
      width: 72px;
    }

    span {
      font-size: 32px;
    }
  }
`

export const TopHeader = styled.img`
  width: 38vw;
  max-width: 500px;
  margin-top: 2.083vw;

  @media (max-width: 768px) {
    width: 68vw;
    max-width: 388px;
    margin-top: 3rem;
  }

  @media (max-width: 560px) {
    margin-top: 4.5rem;
  }
`

export const TopImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 320px;

  @media (max-width: 768px) {
    width: 228px;
  }

  @media (max-width: 560px) {
    width: 168px;
  }
`

export const BottomImage = styled.img`
  display: block;
  object-fit: contain;
  z-index: 99;
  left: 12vw;
  width: 148px;

  @media (max-width: 560px) {
    width: 102px;
  }
`

export const Slot = styled.div`
  position: absolute;
  bottom: 48px;
  left: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media (max-width: 560px) {
    left: 12px;
    bottom: 188px;
  }

  @media (max-width: 560px) and (pointer: coarse) {
    left: 8px;
    bottom: 68px;
  }
`

export const Rule = styled.div`
  position: absolute;
  bottom: 32px;
  right: 6vw;
  width: 368px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    margin: 0;
    color: #faff91;
  }

  ul {
    padding-left: 8px;
    margin-top: 2px;
  }

  li {
    margin-bottom: 8px;
  }

  @media (max-width: 560px) {
    right: 12px;
    bottom: 22px;
    width: 262px;
  }

  @media (max-width: 560px) and (pointer: coarse) {
    bottom: -2px;
    width: 248px;
    font-size: 11px;

    ul {
      margin: 0;
    }
  }
`
