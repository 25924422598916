import React, { useState, useImperativeHandle, useEffect } from 'react'
import { StyledModal, Content } from './styled'
import { Button, Form, Input, notification } from 'antd'
import PhoneInput from 'antd-phone-input'
import FormItem from 'antd/es/form/FormItem'
import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

type Item = {
  prizeText?: string
  slot?: number
  isAddMoreTurn?: boolean
  isLose?: boolean
}

const sendReward = async (params: any) => {
  return await axios
    .post(
      `${process.env.REACT_APP_API_URL}/lucky-wheel/send-award`,
      params,
    )
    .then((res) => res.data)
}

const View = React.forwardRef(({ token }: any, ref) => {
  const [isShow, setShowModal] = useState(false)
  const [item, setItem] = useState<Item>()
  const [form] = Form.useForm()

  const { mutateAsync } = useMutation({
    mutationFn: sendReward,
    onSuccess: (res) => {
      if (res.message === 'EMAIL_OR_PHONE_EXIST') {
        notification.error({
          message: 'Có lỗi xảy ra',
          description: 'Email hoặc số điện thoại đã được sử dụng',
        })
      } else {
        notification.info({
          message: 'Vui lòng gặp Ban tổ chức để nhận thưởng.',
          placement: 'top',
          duration: 15,
        })
        onClose()
      }
    },
  })

  const onClose = () => {
    setItem(undefined)
    setShowModal(false)
  }

  const onFinish = async (values: any) => {
    await mutateAsync({
      token,
      email: values.email,
      phone: `${values.phone.countryCode}${values.phone.areaCode}${values.phone.phoneNumber}`,
    })
  }

  const validator = (_: any, { valid }: any) => {
    // if (valid(true)) return Promise.resolve(); // strict validation
    if (valid()) return Promise.resolve() // non-strict validation
    return Promise.reject('Số điện thoại không hợp lệ')
  }

  useImperativeHandle(ref, () => ({
    open: (data: Item) => {
      setItem(data)
      setShowModal(true)
    },
  }))

  return (
    <StyledModal
      open={isShow}
      footer={null}
      closable={item?.prizeText === undefined}
      width={600}
      centered
      onCancel={!item?.prizeText ? onClose : undefined}
      closeIcon={<></>}
    >
      <Content>
        {item?.isLose ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                fontSize: 30,
                color: '#575757',
                textAlign: 'center',
              }}
            >
              Chúc bạn may mắn lần sau
            </div>
            <div
              style={{
                fontSize: 24,
                color: 'green',
                textAlign: 'center',
              }}
            >
              Bạn còn lại: {item.slot} lượt quay
            </div>
          </div>
        ) : item?.isAddMoreTurn ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                fontSize: 30,
                color: '#575757',
                textAlign: 'center',
              }}
            >
              Chúc mừng bạn đã nhận thêm 1 lượt quay
            </div>
            <div
              style={{
                fontSize: 24,
                color: 'green',
                textAlign: 'center',
              }}
            >
              Bạn còn lại: {item.slot} lượt quay
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                fontSize: 30,
                color: '#575757',
                textAlign: 'center',
              }}
            >
              Chúc mừng bạn đã nhận được:
            </div>
            <div
              style={{
                fontSize: 26,
                color: 'green',
                textAlign: 'center',
              }}
            >
              {item?.prizeText}
            </div>

            <div
              style={{
                fontSize: 20,
                color: '#575757',
                marginTop: 20,
                marginBottom: 5,
                textAlign: 'center',
              }}
            >
              Vui lòng nhập thông tin để tham gia workshop.
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyItems: 'center',
                alignItems: 'center',
                marginTop: 5,
              }}
            >
              <Form
                style={{ width: '100%' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}
                title="Vui lòng nhập thông tin để tham gia workshop."
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: 'Vui lòng nhập email' },
                    { type: 'email', message: 'Email không hợp lệ' },
                  ]}
                >
                  <Input placeholder="Nhập email" />
                </Form.Item>
                <FormItem
                  name="phone"
                  rules={[{ validator }]}
                  label="Số điện thoại"
                >
                  <PhoneInput enableSearch country="vn" />
                </FormItem>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 30,
                  }}
                >
                  <Button
                    type="primary"
                    style={{ width: 200 }}
                    htmlType="submit"
                  >
                    Nhận quà
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Content>
    </StyledModal>
  )
})

export default View
