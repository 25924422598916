import { useEffect, useMemo, useRef, useState } from 'react'
import {
  BottomImage,
  GradientBg,
  Rule,
  Slot,
  SlotWrapper,
  Spin,
  SpinBottom,
  SpinInnerWrapper,
  SpinWrapper,
  StartButton,
  TopHeader,
  TopImage,
} from './styled'
import Modal from './Modal'
import axios from 'axios'
import _shuffle from 'lodash/shuffle'
import _flattenDeep from 'lodash/flattenDeep'
import _findIndex from 'lodash/findIndex'
import { useMutation, useQuery } from '@tanstack/react-query'
import Cookies from 'js-cookie'

const fetchRewards = async (params: any) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/lucky-wheel/award-list`, {
      params,
    })
    .then((res) => res.data)
}

const spinLuckyWheel = async (params: any) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/lucky-wheel/spin`, params)
    .then((res) => res.data)
}

const deviceId = () => {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform
  const randomString =
    Math.random().toString(20).substring(2, 14) +
    Math.random().toString(20).substring(2, 14)

  return `${userAgent || ''}-${platform || ''}-${randomString}`
    .replace(/[^a-zA-Z ]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

const App = () => {
  const modalRef = useRef<any>(null)
  const [wheelSpinning, setWheelSpinning] = useState(false)
  const [slot, setSlot] = useState(1)
  const theWheel = useRef<any>()

  // const runningAudio = new Audio('/sound/dangquay.mp3')
  const winAudio = new Audio('/sound/votay.mp3')
  const loseAudio = new Audio('/sound/matluot.mp3')
  let wheelPower = 13

  const token = useMemo(() => {
    const gameToken = Cookies.get('wheel-token') || deviceId()

    Cookies.set('wheel-token', gameToken)

    return gameToken
  }, [])

  const { data } = useQuery({
    queryKey: ['question'],
    queryFn: () => fetchRewards({ token }),
  })

  const { mutateAsync: spinLuckyWheelMutate } = useMutation({
    mutationFn: spinLuckyWheel,
  })

  const awards = useMemo(() => {
    const newData = (data?.awards || []).map((item: any, index: number) => {
      const newSegment = new Array(item.quantityArea).fill(1).map(() => ({
        id: item.id,
        text: item.name,
      }))

      return newSegment
    })

    const flattenData = _shuffle(_flattenDeep(newData))

    return flattenData.map((a: any, index) => ({
      ...a,
      textFontSize: 15,
      fillStyle: index % 2 === 0 ? '#cf0d2e' : 'white',
      size: winwheelPercentToDegrees(100 / flattenData.length),
      textFillStyle: index % 2 === 0 ? '#fff' : 'black',
    }))
  }, [data])

  const startSpin = async () => {
    if (wheelSpinning || slot === 0) return

    const response = await spinLuckyWheelMutate({ token })
    const segmentIndex = _findIndex(
      awards,
      (a: any) => a.id === response.award.id,
    )

    setWheelSpinning(true)

    theWheel.current.animation.spins = wheelPower
    theWheel.current.animation.stopAngle = theWheel.current.getRandomForSegment(
      segmentIndex + 1,
    )
    theWheel.current.rotationAngle = 0
    theWheel.current.startAnimation()
    // runningAudio?.play()
  }

  const alertPrize = (indicatedSegment: any) => {
    setWheelSpinning(false)

    if (indicatedSegment.id === 'V01') {
      setSlot((prev) => {
        loseAudio.play()
        modalRef.current?.open({ isLose: true, slot: prev - 1 })
        return prev - 1
      })
    }
    // else if (indicatedSegment.id === 'V02') {
    //   winAudio.play()
    //   modalRef.current?.open({ isAddMoreTurn: true, slot })
    // } 
    else {
      winAudio.play()
      modalRef.current?.open({
        prizeText: indicatedSegment.text,
      })
      setSlot((prev) => (prev === 1 ? 0 : prev - 1))
    }
  }

  useEffect(() => {
    data?.remaining > 0 ? setSlot(1) : setSlot(0)
    if (awards.length === 0) return

    if (data.award) {
      setSlot(0)
      modalRef.current?.open({
        prizeText: data.award.name,
      })
    }

    let audio = new Audio('http://dougtesting.net//elements/sound/tick.mp3') // Tạo đối tượng âm thanh và tải tập tin tick.mp3.

    theWheel.current = new Winwheel({
      outerRadius: 275,
      innerRadius: 0,
      // textFontSize: 24,
      // textOrientation: 'horizontal',
      // textAlignment: 'center',
      // textLineWidth: 0,
      drawText: true,
      textFontSize: 20, // Set text options as desired.
      textOrientation: 'horizontal',
      textAlignment: 'center',
      textMargin: 15,
      textFontFamily: 'arial',
      textStrokeStyle: 'transparent',
      textFillStyle: 'white',
      lineWidth: 0,
      strokeStyle: 'transparent',
      numSegments: awards.length,
      responsive: true,
      segments: awards,
      animation: {
        type: 'spinToStop',
        duration: 5,
        spins: 10,
        callbackFinished: alertPrize,
        callbackSound: () => {
          audio.pause()
          audio.currentTime = 0
          audio.play()
        },
        soundTrigger: 'pin',
      },
    })
  }, [data])

  return (
    <SpinWrapper>
      <Modal ref={modalRef} token={token} />
      <div className="pyro">
        <div className="before" style={{ zIndex: 9999 }} />
        <div className="after" />
      </div>

      <Spin>
        <TopHeader src="img/hea3.png" />
        <TopImage src="img/top-image.png" />

        <SpinInnerWrapper>
          <canvas
            id="canvas"
            width={550}
            height={550}
            data-responsiveminwidth={160}
            data-responsivescaleheight="true"
            data-responsivemargin={30}
          />
          <GradientBg />
          <StartButton
            isFinish={slot === 0}
            isSpinning={wheelSpinning}
            onClick={startSpin}
          />

          <SpinBottom src="img/de-vong-quay.png" />
        </SpinInnerWrapper>

        <Slot>
          {/* <BottomImage src="img/bot2.png" /> */}
          <SlotWrapper>
            <img src="/img/banglixi.png" />
            <span id="xuatluotquay">{slot}</span>
          </SlotWrapper>
        </Slot>

        {/* <Rule>
          <h3>THỂ LỆ:</h3>
          <ul>
            <li>
              <strong>Đối tượng tham gia</strong>: Tất cả khách hàng tham gia
              vào liên kết này
            </li>
            <li>
              <strong>Hình thức tham gia</strong>: Quay vòng quay may mắn trên
              website của chương trình
            </li>
            <li>
              Thời gian áp dụng:{' '}
              <strong>Từ ngày 29/01/2024 - 29/02/2024</strong>
            </li>
            <li>
              Mỗi khách hàng với mỗi số email và điện thoại chỉ được quay 1 lần
              duy nhất
            </li>
          </ul>
        </Rule> */}
      </Spin>

      <audio id="matluot">
        <source src="/sound/matluot.mp3" type="audio/mpeg" />
      </audio>
    </SpinWrapper>
  )
}

export default App
